import { useState, useEffect } from "react"

import { gql } from "@apollo/client"
import { parseCookies } from 'nookies'


export const checkPhoneNumberQuery = gql`
    query checkphoneNumber($phoneNumber: String!){
        checkPhoneNumber(phoneNumber: $phoneNumber)
    }
`

export const verifyOTPQuery = gql`
    mutation verifyOTP($phoneNumber: String! $otp: String!){
        verifyOTP(phoneNumber: $phoneNumber otp: $otp){
            message
            status
        }
    }
`

export const sendOTPQuery = gql`
    mutation sendOTP($phoneNumber: String!){
        sendOTP(phoneNumber: $phoneNumber method: SMS){
            status
            message
        }
    }
`

export const createPINQuery = gql`
mutation createPIN(
    $phoneNumber: String!
    $pin: String!
    ){
    createPIN(phoneNumber: $phoneNumber pin: $pin){
      isPakdok
      status
      token
    }
  }
`

export const loginMemberQuery = gql`
mutation loginMember(
    $phoneNumber: String!
    $pin: String!
){
    loginMember(phoneNumber: $phoneNumber pin: $pin){
      message
      status
      token
    }
  }
`

export const RegisterQuery = gql`
mutation registerMember(
    $phoneNumber: String!
    $fullName: String!
    $dateOfBirth: LocalDate!
    $email: String
    $gender: Gender
){
    registerMember(
    phoneNumber: $phoneNumber
    fullName: $fullName
    dateOfBirth: $dateOfBirth
    email: $email
    gender: $gender
    ){
        status
        isPakdok
        token
        member{
            dateOfBirth
            email
            fullName
            phoneNumber
          }
    }
}
`

export const registerPakDokQuery = gql`
mutation registerPakDok(
    $phoneNumber: String!
    $password: String!
    $email: String!
    ){
    registerPakDok(
      password:  $password
      phoneNumber: $phoneNumber
      email: $email
    )
  }
`

export const getProvincesQuery = gql`
query getProvinces{
    getProvinces{
      provinceId
      provinceName
      regencies{
        regencyId
        regencyName
      }
    }
  }
`

export const bpjsInquiryQuery = gql`
mutation bpjsInquiry(
  $periode: Int!
  $noVa: String!
){
  bpjsInquiry(periode: $periode noVa: $noVa){
    status
    message
    detail{
      biayaAdmin
      biayaIuran
      inquiryId
      jumlahAnggota
      jumlahPeriode
      namaPeserta
      noVa
      noVaKepalaKeluarga
      totalBayar
    }
  }
}
`
export const getBpjsBankCodeQuery = gql`
query getBpjsBankCode{
  getBpjsBankCode{
    code
    icon
    name
    serviceFee
  }
}
`

export const BpjsPaymentRequestQuery = gql`
mutation bpjsPaymentRequest($bankCode: String! $inquiryId: Long!){
  bpjsPaymentRequest(bankCode: $bankCode inquiryId: $inquiryId){
    expiresAt
     howToPay{
       instruction
       paymentType
     }
     invoiceNo
     paymentChannelIcon
     paymentChannelName
     referenceId
     totalAmount
     virtualAccountNumber
   }
}
`
export const getHealthPCRQuery = gql`
query getHealthPCR($source: HealthCovCatalogSource!){
  getHealthPCR(source: $source){
    catalogId
    discountPercentage
    discountPrice
    discountType
    id
    maxItem
    price
    text
    tierLogo
    title
  }
}
`

export const manualPaymentQuery = gql`
mutation manualPayment($item: [CatalogItemInput]){
  manualPayment(orderInput: {
    item: $item
  }){
    bankAccount{
      bankAccountName
      bankAccountNumber
      bankIcon
      bankName
    }
    invoiceNo
    referenceId
    totalAmount
    transferNote
  }
}
`

export const newTestPCRQuery = gql`
mutation newTestPCR(
  $address: String!
  $province: String!
  $city: String!
  $testTime: String!
  $waPhoneNumber: String!
  $testDate: LocalDate!
  $email: String!
  $referenceId: Int!
){
  newTestPCR(
    address: $address
    province: $province
    city: $city
    testTime: $testTime
    waPhoneNumber: $waPhoneNumber
    testDate: $testDate
    email: $email
    referenceId: $referenceId
  ){
    message
    status
  }
}
`

export const getTestPCRQuery = gql`
query getTestPCR{
  getTestPCR{
    address
    city
    email
    memberUuid
    province
    referenceId
    testDate
    testId
    testName
    testStatus
    testTime
    waPhoneNumber
  }
}
`

export const getBpjsTransactionsQuery = gql`
query getBpjsTransactions{
  getBpjsTransactions{
    bpjsDetail{
      biayaAdmin
      biayaIuran
      inquiryId
      jumlahAnggota
      jumlahPeriode
      namaPeserta
      noBpjs
      noVAKeluarga
      noVAKepalaKeluarga
    }
    invoiceNo
    name
    paymentMethod
    paymentStatus
    referenceId
    serviceFee
    totalDiscount
    totalPointReward
    totalPrice
    transactionDate
  }
}
`

export const getBpjsPendingPaymentQuery = gql`
query getBpjsPendingPayment($referenceId: Long!) {
  getBpjsPendingPayment(referenceId: $referenceId) {
    expiresAt
    howToPay {
      instruction
      paymentType
    }
    invoiceNo
    paymentChannelIcon
    paymentChannelName
    referenceId
    totalAmount
    virtualAccountNumber
  }
}
`

export const testPCRDetailQuery = gql`
query testPCRDetail($testId: Int!){
  testPCRDetail(testId: $testId){
    address
    email
    memberUuid
    testCity
    testDate
    testId
    testName
    testProvince
    testItem{
      catalogId
      name
      quantity
      pointReward
      discount
      
    }
    testResult
    testResultDocs{
      documentName
      documentUrl
    }
    waPhoneNumber
    testStatus
    
  }
}
`

export const getMemberQuery = gql`
query getMember{
  getMember{
    fullName
  }
}
`

// let authToken = parseCookies().authToken ? `Bearer ${parseCookies().authToken}` : null
// console.log("streamworx", authToken)

// export const STREAMWORX_CONFIG = {
//   context: {
//     clientName: 'streamworx',
//     headers: {
//       authorization: authToken,
//     },
//   }
// }


const STREAMWORX = (variables) => {
  const [getAuthToken, setGetAuthToken] = useState("")

  useEffect(() => {
    const authTokenNew = parseCookies().authToken ? `Bearer ${parseCookies().authToken}` : null
    setGetAuthToken(authTokenNew)

  }, [])

  const STREAMWORX_CONFIG_NEW = {
    variables: variables,
    context: {
      clientName: 'streamworx',
      headers: {
        authorization: getAuthToken,
      },
    }
  }

  return STREAMWORX_CONFIG_NEW
}


export default STREAMWORX