import React, { useState, useEffect, useContext } from "react"
import { Link } from "components/anti/link/link"
import { Modal } from "components/anti/modal/modal"

import DesktopMenu from "./components/desktop-menu"
import MobileMenu from "./components/mobile-menu"
import SearchMenu from "./components/search-menu"

import logoDark from "assets/img//logo_dark.png"
import logoLight from "assets/img/logo.svg"
import profileIcon from "assets/img/profile.svg"

import { WithLocation } from "components/anti/utils/utils"
import { parseCookies, destroyCookie } from "nookies"

import { LoadingContext } from "src/context/loading-context"

import { useQuery } from "@apollo/client"
import { getMemberQuery } from "components/utils/streamworx"

const Navbar = ({ location, path, lang, noCover }) => {
  // set login/logout cookies
  const cookies = parseCookies()
  const authToken = cookies.authToken

  // Navbar expand config
  // Change the const below & config on variables/navbar.scss when setting the .navbar-expand-*
  // e.g. sm, md, lg, xl, xxl
  const navExpand = "lg"

  // Default theme
  // Options: "navbar-dark", "navbar-light"
  const navTheme = "navbar-dark"

  // Default position
  // Options: "fixed-top", "absolute-top", ""
  const navPosition = "fixed-top"

  // IF NO NAVBAR EXPAND USED (no responsive navbar), do the following:
  // 1. In variables/navbar.scss, set $navbar-top-height-mobile & $navbar-top-height-desktop to 0
  // 2. In variables/navbar.scss, set $navbar-main-height-mobile & $nabvar-main-height-desktop to the same height
  // 3. In variables/navbar.scss, set $navbar-expand-up to ""
  // 4. In navbar.js, edit const navbarExpand to ""

  // MENU - MOBILE
  // Open / close menu state & handler
  const [mobileMenu, setMobileMenu] = useState({
    initial: false,
    clicked: null,
  })

  // Toggler accordion navbar when closing navbar
  const [expanded, setExpanded] = useState("undefined")

  // Toggler disabled state & handler
  const [disabledMenu, setDisabledMenu] = useState(false)
  const disableMenu = () => {
    setDisabledMenu(!disabledMenu)
    setTimeout(() => {
      setDisabledMenu(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleMenuMobile = () => {
    setExpanded("undefined")
    disableMenu()
    if (mobileMenu.initial === false) {
      setMobileMenu({ initial: null, clicked: true })
    } else if (mobileMenu.clicked === true) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    } else if (mobileMenu.clicked === false) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    }
  }

  //mobile navbar accordion close when navbar moblie is closing

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : "undefined")
  }

  useEffect(() => {
    const navToggler = document.querySelector(".nav-toggler")
    const navMobileMenu = document.querySelector(".mobile-menu")
    const navBrand = document.querySelector(".navbar-brand")
    const menuBg = document.querySelector(".menu-bg")
    const html = document.querySelector("html")
    const body = document.querySelector("body")
    // prettier-ignore
    if (mobileMenu.clicked === false) {
      // Toggle inactive
      navToggler.classList.add("inactive")
      navBrand.classList.remove("hide")
      navMobileMenu.classList.remove("show")
      navMobileMenu.classList.add("hide")
      menuBg.classList.remove("show")
      menuBg.classList.add("hide")
      // body.classList.remove("no-scroll")
      // html.classList.remove("no-scroll")
    } else if (mobileMenu.clicked === true || (mobileMenu.clicked === true && mobileMenu.initial === null)) {
      // Toggle active
      navBrand.classList.add("hide")
      navToggler.classList.remove("inactive")
      navMobileMenu.classList.add("show")
      menuBg.classList.add("show")
      // body.classList.add("no-scroll")
      // html.classList.add("no-scroll")
    }
  }, [mobileMenu])

  // SEARCH
  // Open / close menu state & handler
  const [search, setSearch] = useState({
    initial: false,
    clicked: null,
  })
  // Toggler disabled state & handler
  const [disabledSearch, setDisabledSearch] = useState(false)
  const disableSearch = () => {
    setDisabledSearch(!disabledSearch)
    setTimeout(() => {
      setDisabledSearch(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleSearch = () => {
    disableSearch()
    if (search.initial === false) {
      setSearch({ initial: null, clicked: true })
    } else if (search.clicked === true) {
      setSearch({ clicked: !search.clicked })
    } else if (search.clicked === false) {
      setSearch({ clicked: !search.clicked })
    }
  }
  useEffect(() => {
    const searchMenu = document.querySelector(".search-menu")
    const navbarMain = document.querySelector(".navbar-main")
    // prettier-ignore
    if (search.clicked === false) {
      // Toggle inactive
      searchMenu.classList.remove("show")
      searchMenu.classList.add("hide")
      navbarMain.classList.remove("search-show")
      navbarMain.classList.add("search-hide")
    } else if (search.clicked === true || (search.clicked === true && search.initial === null)) {
      // Toggle active
      searchMenu.classList.add("show")
      navbarMain.classList.add("search-show")
    }
  }, [search])

  //disable transparent after scroll
  // const [transparent, setTransparent] = useState(true)

  // const listenScrollEvent = () => {
  //   if (location.pathname === "/" || location.pathname === "/about") {
  //     if (window.scrollY > 500) {
  //       setTransparent(false)
  //     } else {
  //       setTransparent(true)
  //     }
  //   }
  // }

  const handleProfile = () => {
    const logOutMenu = document.querySelector(".logout-dropdown")
    if (logOutMenu.classList.contains("d-none")) {
      logOutMenu.classList.remove("d-none")
      logOutMenu.classList.add("d-block")
    } else {
      logOutMenu.classList.remove("d-block")
      logOutMenu.classList.add("d-none")
    }
  }

  const handleLogout = () => {
    destroyCookie({}, "authToken")
    window.location.href = "/"
  }

  // useEffect(() => {
  //   if (location.pathname === "/" || location.pathname === "/about") {
  //     window.addEventListener("scroll", listenScrollEvent)
  //   } else {
  //     setTransparent(false)
  //   }
  // }, [transparent])

  //on scroll
  const [navScroll, setNavScroll] = useState(false)
  const [navOpen, setNavOpen] = useState(false)
  const [coverExist, setCoverExist] = useState(true)

  const { initialLoading } = useContext(LoadingContext)

  useEffect(() => {
    const navbar = document.querySelector(".navbar")
    const containsClass = navbar.classList.contains("light")

    window.onscroll = function() {
      if (!containsClass) {
        if (window.pageYOffset > 5) {
          setNavScroll(true)
        } else {
          setNavScroll(false)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (!initialLoading) {
      const cover = document.querySelector(".cover")
      if (cover) {
        setCoverExist(true)
      } else {
        setCoverExist(false)
      }
    }
  }, [initialLoading])

  //get user login name
  const [userName, setUserName] = useState("")
  const authTokenNew = parseCookies().authToken
    ? `Bearer ${parseCookies().authToken}`
    : null
  const streamworx_config = {
    context: {
      clientName: "streamworx",
      headers: { authorization: authTokenNew },
    },
  }

  const { data: getMember } = useQuery(getMemberQuery, streamworx_config)

  useEffect(() => {
    if (getMember) {
      const name = getMember.getMember.fullName
      const firstName = name.toLowerCase().split(" ")
      setUserName(firstName[0])
    }
  }, [getMember])

  // // Language state handler
  const [pathname, setPathname] = useState(path)

  useEffect(() => {
    const trimPath = pathname?.replace("/en", "")?.replace(/\/$/, "")
    setPathname(trimPath)
  }, [pathname])

  // modal download
  const [showModal, setShowModal] = useState(null)

  return (
    <>
      {/* When giving .navbar-expand-*, don't forget to modify the variables on _navbar.scss */}
      <nav
        className={`navbar ${navTheme} navbar-expand-${navExpand} ${navPosition} ${
          coverExist
            ? noCover || navScroll || navOpen
              ? ""
              : "transparent"
            : ""
        }`}
      >
        <div className="navbar-top">
          <div className="container">
            <ul className="col-left">
              {/* <li className="nav-item">
                <Link className={`nav-link lang-btn ${lang === "id" && "active"}`} to={`${pathname ? pathname : "/"}`}>
                  ID
                </Link>
              </li>
              <li className="nav-item d-flex align-items-center justify-content-center">
                <span className="text-white"> | </span>
              </li>
              <li className="nav-item">
                <Link className={`nav-link lang-btn ${lang === "en" && "active"}`} to={`/en${pathname}`}>
                  EN
                </Link>
              </li> */}
            </ul>
            <ul className="col-right">
              <li className="nav-item">
                <Link
                  className="nav-link text-uppercase font-weight-bold"
                  to="/download"
                  // onClick={() => setShowModal("modal-download")}
                >
                  Download App
                </Link>
              </li>
              <li className="nav-item">
                {authToken ? (
                  <div className="navbar-logout">
                    <div className="logout-btn" onClick={handleProfile}>
                      <img src={profileIcon} className="img-fluid" alt="icon" />
                      <p className="mb-0 mx-2 text-capitalize">{`Hi, ${userName}!`}</p>
                      <i className="air ai-angle-down" />
                    </div>
                    <div
                      className="logout-dropdown d-none"
                      onClick={handleLogout}
                    >
                      <span>Logout</span>
                    </div>
                  </div>
                ) : (
                  <Link
                    className="nav-link text-uppercase font-weight-bold"
                    to="/login"
                  >
                    Login/Register
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>

        <div className={`navbar-main`}>
          <div className="container">
            <ul className="col-left">
              <li className="nav-item-brand">
                {/* prettier-ignore */}
                <Link className="navbar-brand" to="/">
                  <img src={logoLight} className="logo-light img-fluid" alt="Logo" />
                  {/* <img src={logoDark} className="logo-dark img-fluid" alt="Logo"/> */}
                </Link>
              </li>
            </ul>

            {/* Mobile */}
            <ul className={`col-right d-${navExpand}-none`}>
              {/* <li className="nav-item">
                <Link className="nav-icon" to="/">
                  <i className="ais ai-shopping-bag" />
                </Link>
              </li>
              <li className="nav-item">
                <button
                  className="nav-icon search-toggler"
                  disabled={disabledSearch}
                  onClick={handleSearch}
                >
                  <i className="ais ai-search" />
                </button>
              </li> */}
              <li className="nav-item">
                <button
                  className="nav-icon nav-toggler inactive absolute"
                  disabled={disabledMenu}
                  onClick={handleMenuMobile}
                >
                  <span className="icon-bar top-bar" />
                  <span className="icon-bar middle-bar" />{" "}
                  <span className="icon-bar bottom-bar" />
                </button>
              </li>
            </ul>

            {/* Desktop */}
            <DesktopMenu
              disabledSearch={disabledSearch}
              handleSearch={handleSearch}
              navExpand={navExpand}
              location={location}
              setNavOpen={setNavOpen}
            />
          </div>
          {/* <SearchMenu handleSearch={handleSearch} variant="expand" /> */}
        </div>

        <div className="menu-bg" />
        <MobileMenu
          mobileMenu={mobileMenu}
          navExpand={navExpand}
          variant="slide-right"
          location={location}
          accordionChange={handleChange}
          accordionExpanded={expanded}
        />
      </nav>
      <Modal
        id="modal-download"
        title=""
        text=""
        isShowing={showModal}
        imgAlt="IcSuccess"
        className="modal-md bayar-tagihan-modal"
      />
    </>
  )
}

export default WithLocation(Navbar)
