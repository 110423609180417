import React from "react"
import PropTypes from "prop-types"

import { Img } from "components/anti/utils/utils"

import img from "assets/img/bg_default.jpg"

export const Image = ({ className, id, ratio, src, imgClassName, alt }) => {
  return (
    <div className={`image ratio ${ratio} ${className}`} id={id}>
      <div className="outer-content">
        <div className="inner-content">
          <Img src={src} className={`w-100 ${imgClassName}`} alt={alt} />
        </div>
      </div>
    </div>
  )
}

Image.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.any,
  ratio: PropTypes.string, // e.g. .r-16-9, .r-sm-3-2, .r-md-4-3, .r-lg-5-4, .r-xl-1-1
  src: PropTypes.string,
  alt: PropTypes.string,
  imgClassName: PropTypes.string,
}

Image.defaultProps = {
  className: "",
  imgClassName: "",
  ratio: "r-16-9",
  src: img,
  alt: "Image",
}
