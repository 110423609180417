import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "components/anti/link/link"
import { Card } from "components/anti/card/card"
// import Accordion from "react-bootstrap/Accordion"
import { Accordion } from "components/anti/accordion/accordion"

const MobileMenu = ({
  navExpand,
  variant,
  location,
  mobileMenu,
  accordionChange,
  accordionExpanded,
}) => {
  const data = useStaticQuery(GET_SERVICE_NAV_DETAILS)
  const servicesList = data?.wordPress?.services?.nodes

  const firstService = servicesList[0].service
  const slicedServices = servicesList.slice(1)

  // //active dropdown class
  // const [activeDropdown, setActiveDropdown] = useState(false)

  // useEffect(() => {
  //   if (location.pathname.indexOf("/layanan/") >= 0) {
  //     setActiveDropdown(true)
  //   }
  // }, [activeDropdown])

  return (
    <>
      <div className={`mobile-menu d-${navExpand}-none ${variant}`}>
        <div className="mobile-menu-content">
          <div className="container">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link anim-1"
                  activeClassName="active"
                  to="/"
                >
                  Beranda
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link anim-2"
                  activeClassName="active"
                  to="/about"
                >
                  Tentang Kami
                </Link>
              </li>
              {/* <li className="nav-item nav-dropdown">
                <Accordion
                  expandIconPlus
                  variant="boxless"
                  title={"Layanan"}
                  expandedKey={"service"}
                  currentExpanded={accordionExpanded}
                  onChange={accordionChange("service")}
                  titleClassName={`nav-dropdown-title ${activeDropdown &&
                    "active"}`}
                  className={`nav-dropdown-border anim-3 w-100 `}
                >
                  <div className="service-wrapper w-100">
                    <Card
                      img={firstService.cover.imagemobile.sourceUrl}
                      className="service-content img-fluid"
                      variant="overlay"
                      title={firstService.cover.title}
                      link={`/services/${servicesList[0].slug}`}
                    >
                      <img
                        src={firstService.cover.logo.sourceUrl}
                        className="service-logo img-fluid"
                        alt="logo"
                      />
                    </Card>
                    <div className="row mt-3">
                      {slicedServices.map((data, i) => {
                        const coverData = data.service
                        return (
                          <div className="col-6 mb-3" key={i}>
                            <Card
                              img={coverData.cover.imagemobile.sourceUrl}
                              className="service-content-small img-fluid"
                              variant="overlay"
                              title={coverData.cover.title}
                              link={`/services/${data.slug}`}
                            >
                              <img
                                src={coverData.cover.logo.sourceUrl}
                                className="service-logo img-fluid"
                                alt="logo"
                              />
                            </Card>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Accordion>
              </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link anim-3"
                  activeClassName="active"
                  to="/membership"
                >
                  Membership
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link anim-4"
                  activeClassName="active"
                  to="/program"
                >
                  Program
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link anim-5"
                  activeClassName="active"
                  to="/work-from-bali"
                >
                  Work From Bali
                </Link>
              </li> */}
              {/* <li className="nav-item">
                <Link
                  className="nav-link anim-6"
                  activeClassName="active"
                  to="/aman-update"
                >
                  Aman Update
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link anim-5"
                  activeClassName="active"
                  to="/bpjskesehatan"
                >
                  BPJS Kesehatan
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link anim-6"
                  activeClassName="active"
                  to="/bantuan"
                >
                  Bantuan
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileMenu

const GET_SERVICE_NAV_DETAILS = graphql`
  query ServiceNav {
    wordPress {
      services {
        nodes {
          slug
          service {
            cover {
              title
              image {
                sourceUrl
              }
              imagemobile {
                sourceUrl
              }
              logo {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`
