import React, { useContext, useEffect, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import Lottie from "react-lottie"

import { LoadingContext } from "src/context/loading-context"

import logoDark from "assets/img/logo-logo-white@3x.png"
import logoLight from "assets/img/logo-logo-white@3x.png"
import LoadingJson from "assets/img/lottie/loading_2.json"

export const Loader = ({ theme, variant, className, effect }) => {
  const { initialLoading, setInitialLoading } = useContext(LoadingContext)
  // prettier-ignore
  useEffect(() => {
    if (variant === "logo") {
      if (effect === "slideUp") {
        gsap
          .timeline()
          .to(".loader .brand", { duration: 0, css: { visibility: "visible" } })
          .from(".loader .brand", { duration: 1, autoAlpha: 0, delay: 1 })
          .to(".loader .brand", { duration: 1, autoAlpha: 0, ease: "expo.inOut" })
          .to(".loader", { duration: 1, height: 0, ease: "expo.inOut" }).then(() => setInitialLoading(false))
      } else if (effect === "fadeOut") {
        gsap
          .timeline()
          .to(".loader .brand", { duration: 0, css: { visibility: "visible" } })
          .from(".loader .brand", { duration: 1, autoAlpha: 0, delay: 1 })
          .to(".loader .brand", { duration: 1.5, autoAlpha: 0, ease: "expo.inOut" })
          .to(".loader", { duration: 1, autoAlpha: 0, ease: "expo.inOut", delay: -0.5 }).then(() => setInitialLoading(false))
      }
    } else {
      gsap
        .timeline()
        .to(".loader .lottie", { duration: 0, css: { visibility: "visible" } })
        .from(".loader .lottie", { duration: .5, autoAlpha: 0, delay: .5 })
        .to(".loader .lottie", { duration: 1.5, autoAlpha: 0, ease: "expo.inOut" })
        .to(".loader", { duration: 1, autoAlpha: 0, ease: "expo.inOut", delay: -.5 }).then(() => setInitialLoading(false))
    }
  }, [])

  return (
    <>
      {/* prettier-ignore */}
      <div className={`loader ${theme} ${variant} ${className} ${!initialLoading ? 'd-none' : ''}`}>
        {variant === "logo" && <img src={`${theme === "dark" ? logoLight : logoDark}`} className="brand img-fluid" alt="Logo" />}
        {variant === "spinner" && <div className="spinner-inner" />}
        {variant === "lottie" && (
          <div className="lottie">
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: LoadingJson,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice"
                }
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}

Loader.propTypes = {
  variant: PropTypes.oneOf(["logo", "spinner", "lottie"]),
  theme: PropTypes.oneOf(["dark", "light"]),
  effect: PropTypes.oneOf(["slideUp", "fadeOut"]),
  className: PropTypes.string,
}

Loader.defaultProps = {
  variant: "logo",
  theme: "dark",
  effect: "fadeOut",
  className: "",
}

export const LoaderFM = ({ theme, variant, className, effect }) => {
  return (
    <div className={`loader ${theme} ${variant} ${className}`}>
      {variant === "logo" && (
        <img
          src={`${theme === "dark" ? logoLight : logoDark}`}
          className="brand img-fluid"
          alt="Logo"
        />
      )}
      {variant === "spinner" && <div className="spinner-inner" />}
    </div>
  )
}

LoaderFM.propTypes = {
  variant: PropTypes.oneOf(["logo", "spinner", "lottie"]),
  theme: PropTypes.oneOf(["dark", "light"]),
  effect: PropTypes.oneOf(["slideUp", "fadeOut"]),
  className: PropTypes.string,
}

LoaderFM.defaultProps = {
  variant: "logo",
  theme: "dark",
  effect: "fadeOut",
  className: "",
}
