import React from "react"

import { Link } from "components/anti/link/link"

import FooterAbout from "./components/about"
import FooterMenu from "./components/menu"
import FooterDownload from "./components/download"
import FooterSocial from "./components/social"

import logoDark from "assets/img/logo_dark.png"
import logoLight from "assets/img/logo-logo-white@3x.png"
import logo from "assets/img/logo-footer.svg"


const Footer = () => {
  const menuLinks = [
    { title: "Tentang Kami", link: "/tentang-kami" },
    { title: "Kontak Kami", link: "/kontak-kami" },
    { title: "BPJS Kesehatan", link: "/bpjskesehatan" },
  ]

  return (
    <footer id="footer">
      <section className="footer-main pt-5">
        <div className="container">
          <div className="row row-4">
            <div className="col-lg-7 col-footer">
              <FooterAbout
                logo={logo}
                text="Dompet Aman merupakan inovasi teknologi yang membantu Anda mencapai keamanan finansial dan kesehatan."
              />
              <hr className="hr-line d-block d-lg-none" />
            </div>
            <div className="col-lg-3 col-footer">
              <FooterMenu title="Quick Links" links={menuLinks} />
            </div>
            <div className="col-lg-2 col-footer footer-social d-none d-lg-block">
              <FooterSocial className="d-none d-lg-flex" />
              {/* <FooterContact
                title="Contact"
                text="Jl. Jurang Mangu Barat no. 8 Tangerang Selatan, Banten"
                links={contactLinks}
              /> */}
            </div>
            <div className="col-lg-7">
              {/* <div className="mt-lg-4 pb-lg-4 h-100 d-lg-flex align-items-end d-none">
                <FooterDownload />
              </div> */}
            </div>
            <div className="col-lg-5 footer-menu mt-md-1">
              <hr className="hr-line d-block d-lg-none" />
              <div className="mt-lg-4">
                <h5 className="caption">Layanan Pengaduan Konsumen</h5>
                <p className="mb-0">
                  Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga
                  Kementerian Perdagangan RI
                </p>
                <div className="nav-item">
                  <Link
                    className="footer-number btn btn-link"
                    to="https://wa.me/+6285311111010"
                  >
                    <span>
                      <i className="aib ai-whatsapp" />{" "}
                    </span>
                    0853 1111 1010
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-line" />
        </div>
      </section>
      <section className="footer-bottom">
        <div className="container">
          <div className="footer-menu d-block d-md-none">
            <div className="navbar-nav">
              <div className="nav-item">
                <Link className="nav-link" to="/kebijakan-privasi">
                  Kebijakan Privasi
                </Link>
              </div>
              <div className="nav-item">
                <Link className="nav-link" to="/syarat-ketentuan">
                  Syarat & Ketentuan
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-left mt-sm-down-4">
              © 2021 Dompet Aman. Site by{" "}
              <a href="https://www.antikode.com/" target="_blank">
                Antikode
              </a>
              .
            </div>
            <div className="col-md-6 col-right d-none d-md-block">
              <Link to="/kebijakan-privasi">Kebijakan Privasi</Link>
              <span className="divider">|</span>
              <Link to="/syarat-ketentuan">Syarat & Ketentuan</Link>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
