import React from "react"
import PropTypes from "prop-types"

import AccordionMaterial from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"

//   Sample for currentExpanded props
//   const [expanded, setExpanded] = useState(listUpdate[0].slug);
//   currentExpanded={expanded}

//   Sample function for onChange props
//   const handleChange = (panel) => (event, newExpanded) => {
//     setExpanded(newExpanded ? panel : false);
//   };
//   onChange={handleChange(data)}


export const Accordion = ({
  forwardRef,
  id,
  variant,
  currentExpanded,
  expandedKey,
  expandIcon,
  expandIconPlus,
  title,
  titleClassName,
  className,
  headerClassName,
  contentClassName,
  defaultExpanded,
  onChange,
  children,
}) => {
  return (
    <>
      {currentExpanded && onChange ? (
        <AccordionMaterial
          className={`accordion ${className} accordion-${variant}`}
          id={id}
          ref={forwardRef}
          defaultExpanded={defaultExpanded === expandedKey}
          expanded={currentExpanded === expandedKey}
          onChange={onChange}
        >
          <AccordionSummary
            expandIcon={expandIcon}
            className={`accordion-header ${headerClassName} ${expandIconPlus &&
              "accordion-header-plus"}`}
          >
            <h4 className={`accordion-title ${titleClassName}`}>{title}</h4>
            {expandIconPlus && (
              <div className="expand-plus">
                <div className="h-bar" />
                <div className="v-bar" />
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails className={`accordion-content ${contentClassName}`}>
            {children}
          </AccordionDetails>
        </AccordionMaterial>
      ) : (
        <AccordionMaterial
          className={`accordion ${className} accordion-${variant}`}
          id={id}
          ref={forwardRef}
          defaultExpanded={defaultExpanded === expandedKey}
        >
          <AccordionSummary
            expandIcon={expandIcon}
            className={`accordion-header ${headerClassName} ${expandIconPlus &&
              "accordion-header-plus"}`}
          >
            <h4 className={`accordion-title ${titleClassName}`}>{title}</h4>
            {expandIconPlus && (
              <div className="expand-plus">
                <div className="h-bar" />
                <div className="v-bar" />
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails className={`accordion-content ${contentClassName}`}>
            {children}
          </AccordionDetails>
        </AccordionMaterial>
      )}
    </>
  )
}

Accordion.propTypes = {
  forwardRef: PropTypes.any,
  // expandKey: PropTypes.any,
  expandIcon: PropTypes.any,
  expandIconPlus: PropTypes.bool,
  id: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
}

Accordion.defaultProps = {
  expandIcon: <i className="air ai-angle-down" />,
  variant: "basic",
  title: "Title",
  titleClassName: "",
  className: "",
  headerClassName: "",
  contentClassName: "",
}
