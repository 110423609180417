import React from "react"
import { Link } from "components/anti/link/link"

const FooterMenu = ({ title, links }) => {
  return (
    <div className="footer-menu">
      <h5 className="caption">{title}</h5>
      <div className="navbar-nav">
        {links.map((link, i) => {
          return (
            <div className="nav-item" key={i}>
              <Link className="nav-link" to={link.link}>
                {link.title}
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FooterMenu
