import React from "react"

import FooterSocial from "./social"
import FooterDownload from "./download"

const FooterAbout = ({ logo, text }) => {
  return (
    <div className="footer-about">
      <div className="d-none d-lg-block mb-4">
        <div className="row">
          <div className="col-md-3 d-flex align-items-center">
            <img src={logo} className="img-fluid footer-logo" alt="Logo" />
          </div>
          <div className="col-md-9">
            <div className="w-md-400px">
              <p className="mb-0">{text}</p>
            </div>
          </div>
          <div className="col-md-9 offset-md-3 mt-3">
            <FooterDownload />
          </div>
        </div>
      </div>
      <div className="d-block d-lg-none">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <img src={logo} className="img-fluid footer-logo mb-0" alt="Logo" />
          <FooterSocial />
        </div>
        <p className="mw-md-500px">{text}</p>
      </div>
      <div className="d-lg-none d-block">
        <FooterDownload />
      </div>
      {/* <div className="btn-group btn-group-download">
        <a href="#" className="mr-3">
          <img src={appStore} className="img-fluid" alt={"Download"} />
        </a>
        <a href="#">
          <img src={playStore} className="img-fluid" alt={"Download"} />
        </a>
      </div> */}
    </div>
  )
}

export default FooterAbout
