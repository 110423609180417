import React, { useState, useEffect } from "react"
import { Link } from "components/anti/link/link"
import { useStaticQuery, graphql } from "gatsby"

const DesktopMenu = ({
  navExpand,
  handleSearch,
  disabledSearch,
  location,
  setNavOpen,
}) => {
  const data = useStaticQuery(GET_SERVICES)
  const servicesList = data?.wordPress?.services?.nodes

  const [dropdown, setDropdown] = useState({
    initial: false,
    clicked: null,
  })
  // Toggler disabled state & handler
  const [disabledDropdown, setDisabledDropdown] = useState(false)
  const disableDropdown = () => {
    setDisabledDropdown(!disabledDropdown)
    setTimeout(() => {
      setDisabledDropdown(false)
    }, 500) // set this with your mobile menu animation duration
  }
  const handleDropdown = () => {
    disableDropdown()
    if (dropdown.initial === false) {
      setDropdown({ initial: null, clicked: true })
      setNavOpen(true)
    } else if (dropdown.clicked === true) {
      setDropdown({ clicked: !dropdown.clicked })
      setNavOpen(false)
    } else if (dropdown.clicked === false) {
      setDropdown({ clicked: !dropdown.clicked })
      setNavOpen(true)
    }
  }

  useEffect(() => {
    const navDropdown = document.querySelector(".nav-dropdown")
    const dropdownMenu = document.querySelector(".dropdown-menu")
    // prettier-ignore
    if (dropdown.clicked === false) {
      // Toggle inactive
      // navDropdown.classList.remove("active")
      dropdownMenu.classList.remove("show")
      dropdownMenu.classList.add("hide")
    } else if (dropdown.clicked === true || (dropdown.clicked === true && dropdown.initial === null)) {
      // Toggle active
      // navDropdown.classList.add("active")
      dropdownMenu.classList.add("show")
    }
  }, [dropdown])

  // //active dropdown class
  // const [activeDropdown, setActiveDropdown] = useState(false)

  // useEffect(() => {
  //   const dropdown = document.querySelector(".dropdown-menu")
  //   for (var i = 0; i < dropdown.childNodes.length; i++) {
  //     if (dropdown.childNodes[i].className == "dropdown-item active") {
  //       setActiveDropdown(true)
  //       break
  //     }
  //   }
  // }, [activeDropdown])

  //active wfb class
  const [activeWfb, setActiveWfb] = useState()

  useEffect(() => {
    if (location.pathname.indexOf("/work-from-bali/") >= 0) {
      setActiveWfb(true)
    }
  }, [activeWfb])

  //active program class
  const [activeProgram, setActiveProgram] = useState()

  useEffect(() => {
    if (location.pathname.indexOf("/program/") >= 0) {
      setActiveProgram(true)
    }
  }, [activeProgram])

  //active aman update class
  const [activeAmanUpdate, setActiveAmanUpdate] = useState()

  useEffect(() => {
    if (location.pathname.indexOf("/aman-update/") >= 0) {
      setActiveAmanUpdate(true)
    }
  }, [activeAmanUpdate])

  return (
    <>
      <div className={`desktop-menu d-none d-${navExpand}-flex`}>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/">
              Beranda
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/about">
              Tentang Kami
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/service">
              Service
            </Link>
          </li> */}
          {/* <li className="nav-item position-relative">
            <button
              // className={`nav-link nav-dropdown ${activeDropdown && "active"}`}
              className={`nav-link nav-dropdown ${activeDropdown && "active"}`}
              disabled={disabledDropdown}
              onClick={handleDropdown}
            >
              Layanan
              <i className="air ai-angle-down ml-2" />
            </button>
            <div className="dropdown-menu">
              {servicesList.map((data, i) => {
                return (
                  <Link
                    className="dropdown-item"
                    activeClassName="active"
                    to={`/services/${data.slug}`}
                    key={i}
                  >
                    <span>{data.title}</span>
                  </Link>
                )
              })}
            </div>
          </li> */}
          <li className="nav-item">
            <Link
              className="nav-link"
              activeClassName="active"
              to="/membership"
            >
              Membership
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              className={`nav-link ${activeWfb && "active"}`}
              activeClassName="active"
              to="/work-from-bali">
              Work From Bali
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              className={`nav-link ${activeProgram && "active"}`}
              activeClassName="active"
              to="/program"
            >
              Program
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              className={`nav-link ${activeAmanUpdate && "active"}`}
              activeClassName="active"
              to="/aman-update"
            >
              Aman Update
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              className={`nav-link`}
              activeClassName="active"
              to="/bpjskesehatan"
            >
              BPJS Kesehatan
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/bantuan">
              Bantuan
            </Link>
          </li> */}
          {/* <li className="nav-item position-relative">
            <button
              // className={`nav-link nav-dropdown ${activeDropdown && "active"}`}
              className={`nav-link nav-dropdown `}
              disabled={disabledDropdown}
              onClick={handleDropdown}
            >
              IND
              <i className="air ai-angle-down ml-2" />
            </button>
            <div className="dropdown-menu">
              <Link className="dropdown-item" activeClassName="active">
                <span>IND</span>
              </Link>
              <Link className="dropdown-item" activeClassName="active">
                <span>ENG</span>
              </Link>
            </div>
          </li> */}
        </ul>
      </div>
    </>
  )
}

export default DesktopMenu

const GET_SERVICES = graphql`
  query getServices {
    wordPress {
      services {
        nodes {
          slug
          title
        }
      }
    }
  }
`
