import React from 'react'

import { Link } from "components/anti/link/link"

import appStore from "assets/img/btn-apple.svg"
import playStore from "assets/img/btn-google.svg"
import comingSoon from "assets/img/img-btn-comingsoon.svg"


const FooterDownload = () => {
    return (
        <div className="btn-group d-flex mt-3">
            <Link to="https://apps.apple.com/us/app/dompet-aman/id1588720217">
                <img
                    src={appStore}
                    className="img-fluid mr-2"
                    alt="App Store"
                />
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.dompetaman">
                <img
                    src={playStore}
                    className="img-fluid ml-2"
                    alt="Play Store"
                />
            </Link>
        </div>
        //     <div className="btn-group btn-group-download">
        //     <a href="#" className="mr-3">
        //         <img src={appStore} className="img-fluid" alt={"Download"} />
        //     </a>
        //     <a href="#">
        //         <img src={playStore} className="img-fluid" alt={"Download"} />
        //     </a>
        // </div>
    )
}

export default FooterDownload